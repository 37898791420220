import React from "react";
import Ticker from "react-ticker";
import { connect } from "react-redux";
class TickerBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsTickerHovered: false,
    };
  }
  render() {
    return (
      /* Ticker is used To show the Currency slide bar  */
      <Ticker
        speed={this.props.Wallets.Currencies ? 10 : 10000}
        move={!this.state.IsTickerHovered}
      >
        {() => {
          if (this.props.Wallets.Currencies) {
            var WalletArray = [];
            for (const curr of this.props.Wallets.Currencies) {
              for (const Wallet of this.props.Wallets.Wallets) {
                if (Wallet.Currency === curr.ThreeDigitName) {
                  if (
                    Wallet.WalletType === "QUICKPAY" ||
                    Wallet.WalletType === "FIAT"
                  ) {
                    WalletArray.push(
                      <li
                        key={curr.ThreeDigitName}
                        onClick={() => {
                          this.props.history.push(
                            "/Wallets?c=" + curr.ThreeDigitName
                          );
                        }}
                        style={{
                          display: "inline-block",
                          listStyle: "none",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          className="item"
                          style={{
                            float: "left",
                            width: "300px",
                            margin: " 0 5px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "bold",
                              textAlign: "center",
                              fontSize: "20px",
                              letterSpacing: "2px",
                            }}
                          >
                            {curr.FullName}
                          </p>
                          <hr style={{ border: "2px solid " + curr.Color }} />
                          <p
                            style={{
                              marginTop: "1rem",
                              textAlign: "center",
                              fontWeight: " bold",
                              fontSize: "18px",
                            }}
                          >
                            {Wallet.Balance.toFixed(8) +
                              " " +
                              curr.ThreeDigitName}
                          </p>
                        </div>
                      </li>
                    );
                  }
                }
              }
            }
            return (
              <ul
                onMouseOver={() => this.setState({ IsTickerHovered: true })}
                onMouseOut={() => this.setState({ IsTickerHovered: false })}
                style={{ whiteSpace: "nowrap", padding:"0px !important" }}
              >
                {WalletArray}
              </ul>
            );
          } else {
            return <div style={{ opacity: 0 }}>W W W</div>;
          }
        }}
      </Ticker>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Wallets: state.Wallets,
  };
};

export default connect(mapStateToProps, null)(TickerBar);
