import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
const mapStateToProps = state => {
    return {
      Wallets: state.Wallets,
      Focused: state.Focused,
      Token: state.Token,
    };
  };
class PrivateRoute extends React.Component {
  render(){
    const { component: Component, ...rest }=this.props;
    let t=localStorage.getItem("b1a531fc052c9e7fb2419c6ed8b9ce12");
    var s=this.props.location;
    if((s.pathname+"").includes("ref"))
    {
      s.search=(s.pathname+"").split("?ref=")[1];
      s.pathname=""
    }
  return (
    <Route
      {...rest}
      render={prop =>
        t&&t!==''? (
          <Component {...prop} />
        ) : (
          <Redirect to={{pathname:"/SignIn?ref="+s.pathname+s.search }} />
        )
      }
    />
  );
    }
}
export default connect(mapStateToProps, null)(withRouter(PrivateRoute));
