export const BaseUrl = "http://coldwallet.watchblock.net/api/"
//export const BaseUrl = "http://localhost:62897/api/";
export const BaseUrlGet="http://coldwallet.watchblock.net/";
export const TradeUrl="http://coldtrade.watchblock.net/"
//export const TradeUrl="http://localhost:21217/"

export const ImageBaseUrl="http://coldwallet.watchblock.net/Images/"
//export const ImageBaseUrl="http://localhost:62897/Images/"
export const DomainName="http://paywalawebtest.watchblock.net/"
//export const DomainName="http://localhost:3000/"
export const WHCCoinUrl="http://sdk.watchblock.net/api/"
export const AuthenticationToken = "858100416504-afcr1kb9jsqi6nu8aql2mr34l1ts0e34*98asdlhj(*&987asdlfkj_)(()*98239487234987Dg$g";

export const AuthenticationTokenId = "b1a531fc052c9e7fb2419c6ed8b9ce12";
export const DefaultCurrencyTokenId="858100416504";
export const UserTypeTokenId="98239487234987Dg$g";
export const UserAuthTokenId="34l1ts0e34*98asdlhj";
export const UserProfileTokenId="62628978300097";


export const BaseCurrencies=[
    {Name:"USD",Image:require("../Assets/Icons/Currencies/USD.png"),symbol:"$"},
    {Name:"EUR",Image:require("../Assets/Icons/Currencies/EUR.png"),symbol:"€"},
    {Name:"ZAR",Image:require("../Assets/Icons/Currencies/ZAR.png"),symbol:"R"},
    {Name:"JPY",Image:require("../Assets/Icons/Currencies/JPY.png"),symbol:"¥"},
    {Name:"AED",Image:require("../Assets/Icons/Currencies/AED.png"),symbol:"د.إ"},
]

