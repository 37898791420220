import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/Assets/css/custom.css";
import "font-awesome/css/font-awesome.min.css";
import "../src/Assets/css/new.css";
import SignUp from "./component/signUp/SignUp";
import SignIn from "./component/signIn/SignIn";
import ForgetPassword from "./component/signIn/ResetPassword/ForgetPassword";
import VerificationCode from "./component/VerificationCode";
import OverView from "./component/overview/OverView";
import SharedLayout from "./component/shared/SharedLayout";
import Wallets from "./component/wallet/Wallet";
import WalaGate from "./component/WalaGate";
import Setting from "./component/settings/Setting";
import RecentInvoices from "./component/history/Invoice/RecentInvoices";
import PrivateRoute from "./router/PrivateRoute";
import PublicRoute from "./router/PublicRoute";
import Loader from "./component/shared/Loader";
import KYC from "./component/security/Kyc/KYC";
import Invoice from "./component/history/Invoice/Invoice";
import PayWalaScreen from "./component/paywala/PayWalaScreen";
import RecentTransaction from "./component/history/Transaction/RecentTransaction";
import TwoFactor from "./component/security/TwoFactorAuthentication/TwoFactorAuthentication";
import TwoFaLogin from "./component/signIn/2FALoginCode/2FALoginCode";
import Reset2FA from "./component/signIn/Reset2FA/Reset2FA";
import EditProfile from "./component/overview/EditProfile/EditProfile";
import ChangePassword from "./component/overview/EditProfile/ChangePassword";
import BasicExchangePlaceholder from "./component/exchange/BasicExchange/BasicExchangePlaceHolder";
import ProExchangePlaceholder from "./component/exchange/ProExchange/ProExchangePlaceHolder";
import MarketPlace from "./component/market/MarketPlace";
import subCategory from "./component/subCategory";
import Asset from "./component/asset/Asset";
import Transfer from "./component/transfer";
const Pages = () => {
  return (
    <Loader
      style={{
        flex: 1,
      }}
    >
      <BrowserRouter basename={"/"}>
        <div>
          <Switch>
            <PublicRoute exact path="/" component={SignUp} />
            <PublicRoute exact path="/SignIn" component={SignIn} />
            <PublicRoute exact path="/SignUp" component={SignUp} />
            <PublicRoute
              exact
              path="/ForgetPassword"
              component={ForgetPassword}
            />
            <PublicRoute
              exact
              path="/VerificationCode"
              component={VerificationCode}
            />
            <PublicRoute exact path="/2FALoginCode" component={TwoFaLogin} />
            <PublicRoute exact path="/Reset2FA" component={Reset2FA} />
            <Fragment>
              <SharedLayout>
                <PrivateRoute
                  exact
                  path="/OverView"
                  component={OverView}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/BasicExchange"
                  component={BasicExchangePlaceholder}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/BasicExchange"
                  component={ProExchangePlaceholder}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/Wallets"
                  component={Wallets}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/WalaGate"
                  component={WalaGate}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/RecentInvoices"
                  component={RecentInvoices}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/Setting"
                  component={Setting}
                ></PrivateRoute>
                <PrivateRoute exact path="/KYC" component={KYC}></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/invoice"
                  component={Invoice}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/RecentTransaction"
                  component={RecentTransaction}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/paywala"
                  component={PayWalaScreen}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/TwoFactorAuthentication"
                  component={TwoFactor}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/MarketPlace"
                  component={MarketPlace}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/Profile"
                  component={EditProfile}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/ChangePassword"
                  component={ChangePassword}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/subCategory"
                  component={subCategory}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/Asset"
                  component={Asset}
                ></PrivateRoute>
                <PrivateRoute
                  exact
                  path="/Transfer"
                  component={Transfer}
                ></PrivateRoute>
              </SharedLayout>
            </Fragment>
          </Switch>
        </div>
      </BrowserRouter>
    </Loader>
  );
};
ReactDOM.render(
  <Provider store={store}>
    <Pages />
  </Provider>,
  document.getElementById("root")
);
