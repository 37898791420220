export const ADD_WALLETS = "ADD_WALLETS";
export const SET_FOCUSED = "SET_FOCUSED";
export const SET_TOKEN = "SET_TOKEN";
export const SET_API_INTERVAL="SET_API_INTERVAL";
export const SET_INTERVAL_STARTED="SET_INTERVAL_STARTED"; 
export const SET_QR="SET_QR";
export const SET_QRMERCHANTKEY="SET_QRMERCHANTKEY";
export const SET_DEFAULTS="SET_DEFAULTS";
export const SET_IS_LOADER_ACTIVE="SET_IS_LOADER_ACTIVE";
export const SET_ISMERCHANT="SET_ISMERCHANT";
