import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
const mapStateToProps = state => {
    return {
      Wallets: state.Wallets,
      Focused: state.Focused,
      Token: state.Token,
    };
  };
class PublicRoute extends React.Component {
  render(){
    const { component: Component, ...rest }=this.props;
    let t=localStorage.getItem("b1a531fc052c9e7fb2419c6ed8b9ce12");
  return (
    <Route
      {...rest}
      render={prop =>
        t&&t!==''? (
          <Redirect to="/OverView" />
          ) : (
            <Component {...prop} />
        )
      }
    />
  );
    }
}
export default connect(mapStateToProps, null)(PublicRoute);
